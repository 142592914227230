import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/containers/layout'
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import { Container } from 'react-bootstrap'
import Seo from "../components/seo/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './post.css'

const Post = ({ data }) => {
  const post = data.markdownRemark
  const image = getImage(post.frontmatter.timage)

   return (
    <Layout>
      <Navbar />
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.introduction}
        image={image}
      />
     <Container fluid className="text_layout">
        <p className="font-weight-light font-italic float-right">{post.frontmatter.date}</p>
        <h2>{post.frontmatter.title}</h2>
        <hr className="mt-0"/>
        <Container className="text-center my-4">
          <GatsbyImage image={image} style={{ display: 'block' }} alt={post.frontmatter.title} />
        </Container>
        <div className="text_introduction">
          <p>{post.frontmatter.introduction}</p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
     </Container>
      <Footer />
    </Layout>
   )
}
export default Post;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        title
        timage {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
            )
         }
       }
       introduction
      }
    }
  }
`
